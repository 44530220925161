import axios from 'axios';

const baseURL = 'https://statbackend.onrender.com/api';
// const baseURL = 'http://localhost:4000/api';

const AuthService = {
  login: async (email, password) => {
    try {
      const response = await axios.post(`${baseURL}/auth/login`, { email, password });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userDataN', JSON.stringify(response.data.user));
      }
      return response;
    } catch (error) {
      throw error;
    }
  },

  signup: async (name, email, phoneNumber, password) => {
    try {
      const response = await axios.post(`${baseURL}/auth/signup`, { name, email, phoneNumber, password });
      // if (response.data.token) {
      //   localStorage.setItem('token', response.data.token);
      //   localStorage.setItem('userDataN', JSON.stringify(response.data.user));
      // }
      return response;
    } catch (error) {
      throw error;
    }
  },

    // Forgot password endpoint
  forgotPassword: async (email) => {
    try {
      const response = await fetch(`${baseURL}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error in forgotPassword:', error);
      throw new Error('Failed to request password reset');
    }
  },

  // Reset password endpoint
  resetPassword: async ({ email, newPassword }) => {
    try {
      const response = await fetch(`${baseURL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, newPassword }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error in resetPassword:', error);
      throw new Error('Failed to reset password');
    }
  },

  getUserEmail: () => {
    return localStorage.getItem('email');
  },

  logout: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('userDataN');
  },

  isLoggedIn: () => !!localStorage.getItem('token'),

  getToken: () => {
      return localStorage.getItem('token');
  }
};

export default AuthService;
