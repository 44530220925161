import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { ForgotPassword } from "./components/ForgotPassword";
import { ResetPassword } from "./components/ResetPassword";
import ScorePage from "./components/ScorePage"; // Import the ScorePage component
import { About } from "./components/about";
import { Contact } from "./components/contact";
import { Features } from "./components/features";
import { Header } from "./components/header";
import { Navigation } from "./components/navigation";
import Login from "./components/login";
import PrivateRoute from "./components/PrivateRoute";
import JsonData from "./data/data.json";
import AuthService from './services/AuthService';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(AuthService.isLoggedIn());
  const [userDataN, setUserDataN] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setLandingPageData(JsonData);
    const storedUserData = localStorage.getItem('userDataN');
    if (storedUserData) {
      setUserDataN(JSON.parse(storedUserData));
    }
  }, []);

  const handleLogout = () => {
    AuthService.logout();
    setIsLoggedIn(false);
    setUserDataN(null);
    localStorage.removeItem('userDataN'); 
    localStorage.removeItem('token'); 
    alert('Logged out successfully.');
    setTimeout(() => {
      navigate('/login');
    }, 2000);
  };

  return (
    <div>
      <Navigation isLoggedIn={isLoggedIn} userDataN={userDataN} onLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<HomePage landingPageData={landingPageData} />} />
        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setUserDataN={setUserDataN} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/record-score"
          element={<PrivateRoute element={ScorePage} isLoggedIn={isLoggedIn} />}
        />
      </Routes>
    </div>
  );
};

const HomePage = ({ landingPageData }) => {
  return (
    <div>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      {/* <Services data={landingPageData.Services} /> */}
      {/* <Gallery data={landingPageData.Gallery} /> */}
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      {/* <Team data={landingPageData.Team} /> */}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;

