import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import './ScorePage.css';

const ScorePage = () => {
  const [sport, setSport] = useState('');
  const [alignment, setAlignment] = useState('singles');
  const [userIdSelf, setUserIdSelf] = useState('');
  const [teamNameSelf, setTeamNameSelf] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [opponentTeamName, setOpponentTeamName] = useState('');
  const [opponentPlayer1Id, setOpponentPlayer1Id] = useState('');
  const [opponentPlayer2Id, setOpponentPlayer2Id] = useState('');
  const [scores, setScores] = useState([
    { teamScore: '', opponentScore: '' },
    { teamScore: '', opponentScore: '' },
    { teamScore: '', opponentScore: '' }
  ]);

  // Validation states and functions
  const [partnerIdError, setPartnerIdError] = useState('');
  const [opponentPlayer1IdError, setOpponentPlayer1IdError] = useState('');
  const [opponentPlayer2IdError, setOpponentPlayer2IdError] = useState('');

  const [isTeamNameEditable, setIsTeamNameEditable] = useState(false);
  const [isOpponentTeamNameEditable, setIsOpponentTeamNameEditable] = useState(false);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userDataN');
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setUserIdSelf(userData.userId);
    }
  }, []);

  // Fetch team name effect
  useEffect(() => {
    if (userIdSelf && partnerId) {
      fetchTeamName(userIdSelf, partnerId);
    } else {
      setTeamNameSelf('');
      setIsTeamNameEditable(true);
    }
  }, [userIdSelf, partnerId]);

  // Fetch opponent team name effect
  useEffect(() => {
    if (opponentPlayer1Id && opponentPlayer2Id) {
      fetchOpponentTeamName(opponentPlayer1Id, opponentPlayer2Id);
    } else {
      setOpponentTeamName('');
      setIsOpponentTeamNameEditable(true);
    }
  }, [opponentPlayer1Id, opponentPlayer2Id]);

  // Function to fetch team name
  const fetchTeamName = async (userId, partnerId) => {
    try {
      const response = await axios.get('https://statbackend.onrender.com/api/matches/getTeamName', {
        params: { userId, partnerId },
        withCredentials: true,
      });
      setTeamNameSelf(response.data.teamName);
      setIsTeamNameEditable(false);
    } catch (error) {
      console.error('Error fetching team name:', error);
      setIsTeamNameEditable(true);
    }
  };

  // Function to fetch opponent team name
  const fetchOpponentTeamName = async (opponentPlayer1Id, opponentPlayer2Id) => {
    try {
      const response = await axios.get('https://statbackend.onrender.com/api/matches/getOpponentTeamName', {
        params: { opponentPlayer1Id, opponentPlayer2Id },
        withCredentials: true,
      });
      setOpponentTeamName(response.data.opponentTeamName);
      setIsOpponentTeamNameEditable(false);
    } catch (error) {
      console.error('Error fetching opponent team name:', error);
      setIsOpponentTeamNameEditable(true);
    }
  };

  // Update score function
  const updateScore = (index, field, value) => {
    const newScores = [...scores];
    newScores[index][field] = value;
    setScores(newScores);
  };

  // Handle change function for alignment
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  // Handle submit function
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if all IDs are distinct
    const allIds = [userIdSelf, partnerId, opponentPlayer1Id, opponentPlayer2Id];
    if (new Set(allIds).size !== allIds.length) {
      toast.error('All IDs must be distinct');
      return;
    }

    const matchData = {
      userIdSelf,
      teamNameSelf,
      partnerId,
      opponentTeamName,
      opponentPlayer1Id,
      opponentPlayer2Id,
      sport,
      format: alignment.charAt(0).toUpperCase() + alignment.slice(1),
      matchSetsScore: scores.map(score => ({
        teamScore: parseInt(score.teamScore, 10) || 0,
        opponentScore: parseInt(score.opponentScore, 10) || 0,
      })),
    };

    try {
      const response = await axios.post('https://statbackend.onrender.com/api/matches/createMatch', matchData, {
        withCredentials: true,
      });
      console.log('Match created successfully:', response.data);
      toast.success('Result of the Match : ' + response.data.match.result);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.status === 500) {
        toast.error('Internal server error');
      } else {
        toast.error('Error creating match!');
      }
    }
  };

  // Function to handle change in partner ID
  const handlePartnerIdChange = (e) => {
    setPartnerId(e.target.value);
    if (e.target.value === userIdSelf) {
      setPartnerIdError('Partner ID must be different from your own ID');
    } else {
      setPartnerIdError('');
    }
  };

  // Function to handle change in opponent player 1 ID
  const handleOpponentPlayer1IdChange = (e) => {
    setOpponentPlayer1Id(e.target.value);
    if (e.target.value === userIdSelf) {
      setOpponentPlayer1IdError('Opponent player ID must be different from your own ID');
    } else if(e.target.value === partnerId){
      setOpponentPlayer1IdError('Opponent player ID must be different from your partner\'s ID');
    } else {
      setOpponentPlayer1IdError('');
    }
  };

  // Function to handle change in opponent player 2 ID
  const handleOpponentPlayer2IdChange = (e) => {
    setOpponentPlayer2Id(e.target.value);
    if (e.target.value === userIdSelf) {
      setOpponentPlayer2IdError('Opponent player ID must be different from your own ID');
    } else if (e.target.value === opponentPlayer1Id) {
      setOpponentPlayer2IdError('Opponent player ID must be different from the first opponent player');
    } else if (e.target.value === partnerId) {
      setOpponentPlayer2IdError('Opponent player ID must be different from your partner\'s ID');
    } else {
      setOpponentPlayer2IdError('');
    }
  };

  return (
    <div className="score-page">
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <h1>Record Your Score</h1>

        <div className="sport-dropdown">
          <label htmlFor="sport">Sport</label>
          <select
            id="sport"
            value={sport}
            onChange={(e) => setSport(e.target.value)}
          >
            <option value="">Choose which Sport</option>
            <option value="Tennis">Tennis</option>
            <option value="Basketball">Basketball</option>
            <option value="Padel">Padel</option>
          </select>
        </div>
        <div className="toggle-button">
          <label style={{ paddingRight: '30px', paddingTop: '10px' }}>Format</label>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Singles or Doubles"
          >
            <ToggleButton value="singles">Singles</ToggleButton>
            <ToggleButton value="doubles">Doubles</ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="user-id-input">
          <label htmlFor="userIdSelf">User ID of Self</label>
          <input
            type="text"
            id="userIdSelf"
            value={userIdSelf}
            readOnly
            placeholder="Enter your user ID"
          />
        </div>

        <div className="partner-id-input">
          <label htmlFor="partnerId">Your Partner's ID</label>
          <input
            type="text"
            id="partnerId"
            value={partnerId}
            onChange={handlePartnerIdChange}
            placeholder="Enter your partner's ID"
          />
          {partnerIdError && <div className="error-message" style={{color:"red", fontSize:"12px"}}>{partnerIdError}</div>}
        </div>

        <div className="team-name-input">
          <label htmlFor="teamNameSelf">Team Name of Self</label>
          <input
            type="text"
            id="teamNameSelf"
            value={teamNameSelf}
            onChange={(e) => setTeamNameSelf(e.target.value)}
            placeholder={teamNameSelf ? teamNameSelf : 'Enter your team name'}
            readOnly={!isTeamNameEditable}
          />
        </div>

        <div className="opponent-player1-input">
          <label htmlFor="opponentPlayer1Id">Opponent Player 1 ID</label>
          <input
            type="text"
            id="opponentPlayer1Id"
            value={opponentPlayer1Id}
            onChange={handleOpponentPlayer1IdChange}
            placeholder="Enter opponent player 1 ID"
          />
          {opponentPlayer1IdError && <div className="error-message" style={{color:"red", fontSize:"12px"}}>{opponentPlayer1IdError}</div>}
        </div>

        <div className="opponent-player2-input">
          <label htmlFor="opponentPlayer2Id">Opponent Player 2 ID</label>
          <input
            type="text"
            id="opponentPlayer2Id"
            value={opponentPlayer2Id}
            onChange={handleOpponentPlayer2IdChange}
            placeholder="Enter opponent player 2 ID"
          />
          {opponentPlayer2IdError && <div className="error-message" style={{color:"red", fontSize:"12px"}}>{opponentPlayer2IdError}</div>}
        </div>

        <div className="opponent-team-input">
          <label htmlFor="opponentTeamName">Opponent Team Name</label>
          <input
            type="text"
            id="opponentTeamName"
            value={opponentTeamName}
            onChange={(e) => setOpponentTeamName(e.target.value)}
            placeholder={opponentTeamName ? opponentTeamName : 'Enter opponent team name'}
            readOnly={!isOpponentTeamNameEditable}
          />
        </div>

        <div className="scoreboard">
          <div className="scoreboard-header">
            <div className="team-name-label">Team Name</div>
            {scores.map((_, index) => (
              <div key={index} className="set-label">{`Set ${index + 1}`}</div>
            ))}
          </div>
          <div className="scoreboard-row">
            <div className="team-label">{teamNameSelf}</div>
            {scores.map((score, index) => (
              <div key={index} className="score-display">
                <input
                  type="number"
                  value={score.teamScore}
                  onChange={(e) => updateScore(index, 'teamScore', e.target.value)}
                  className="score-input"
                />
              </div>
            ))}
          </div>
          <div className="scoreboard-row">
            <div className="team-label">{opponentTeamName}</div>
            {scores.map((score, index) => (
              <div key={index} className="score-display">
                <input
                  type="number"
                  value={
                    score.opponentScore}
                  onChange={(e) => updateScore(index, 'opponentScore', e.target.value)}
                  className="score-input"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="continue-button">
          <button type="submit">Submit Match</button>
        </div>
      </form>
    </div>
  );
};

export default ScorePage;
