import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PrivateRoute = ({ element: Element, isLoggedIn }) => {
  useEffect(() => {
    if (!isLoggedIn) {
      // toast.error('You need to sign up or log in to record a score.');
    }
  }, [isLoggedIn]);

  return isLoggedIn ? <Element /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
