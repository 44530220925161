import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import 'react-toastify/dist/ReactToastify.css';
import './Navigation.css'; // Import your CSS file for Navigation styling

export const Navigation = ({ isLoggedIn, userDataN, onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link to="/" className="navbar-brand page-scroll" onClick={handleLogoClick}>
            <img src="/img/stat_main_logo.png" alt="Stat." style={{ maxWidth: "40%", height: "auto" }} />
          </Link>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <HashLink smooth to="/#features" className="page-scroll">
                Features
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/#about" className="page-scroll">
                About
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/#court-booking" className="page-scroll">
                Book A Court
              </HashLink>
            </li>
            <li>
              <Link to="/record-score" className="page-scroll">
                Record Score
              </Link>
            </li>
            <li>
              <HashLink smooth to="/#contact" className="page-scroll">
                Contact
              </HashLink>
            </li>
            <li className="dropdown">
              {isLoggedIn ? (
                <div>
                  <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {userDataN?.userId}
                    <span className="caret"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li><Link to="/profile" className="page-scroll">My Profile</Link></li>
                    <li><button onClick={handleLogout}>Logout</button></li>
                  </ul>
                </div>
              ) : (
                <Link to="/login" className="page-scroll"> Login / Sign Up </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
