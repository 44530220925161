import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../services/AuthService';

const Login = ({ setIsLoggedIn, setUserDataN }) => {
  const [activeCard, setActiveCard] = useState("signIn");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: ""
  });
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Password validation
    if (name === "password") {
      if (value.length < 8) {
        setPasswordError("Password must be at least 8 characters long.");
      } else {
        setPasswordError("");
      }
    }

    // Confirm password validation
    if (name === "confirmPassword") {
      if (value !== formData.password) {
        setConfirmPasswordError("Passwords do not match.");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (activeCard === "signIn") {
      try {
        const response = await AuthService.login(
          formData.email,
          formData.password
        );
        console.log('Logged in successfully', response.data);
        toast.success('Logged in successfully');
        setIsLoggedIn(true);
        setUserDataN(response.data.user);
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.error);
        } else {
          toast.error('An error occurred while logging in. Please try again.');
        }
      }
    } else {
      try {
        if (formData.password !== formData.confirmPassword) {
          toast.error('Passwords do not match');
          return;
        }

        const response = await AuthService.signup(
          formData.name,
          formData.email,
          formData.phoneNumber,
          formData.password
        );
        console.log('Registered successfully', response.data);
        toast.success(response.data.message);
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } catch (error) {
        console.error('Registration failed', error);

        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.error);
        } else {
          toast.error('An error occurred while registering');
        }
      }
    }
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: ""
    });
  };

  const toggleCard = () => {
    setActiveCard(activeCard === "signIn" ? "signUp" : "signIn");
    setPasswordError("");
    setConfirmPasswordError("");
  };

  return (
    <div className="login-container">
      <div className="card-container">
        {activeCard === "signIn" && (
          <div className="card active">
            <h2>Login</h2>
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="input-container">
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  required
                />
              </div>
              <button type="submit">Sign In</button>
            </form>
            <p className="toggle" onClick={toggleCard}>
              Don't have an account? Sign Up
            </p>
            <Link to="/forgot-password" className="forgot-password-link">
              Forgot Password?
            </Link>
          </div>
        )}
        {activeCard === "signUp" && (
          <div className="card active" style={{marginTop:"50px"}}>
            <h2>Register</h2>
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="input-container">
                <
                input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  required
                />
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="input-container">
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Phone Number"
                  required
                />
              </div>
              <div className="input-container">
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  required
                />
              </div>
              {passwordError && <p className="error" style={{color:"red", fontSize:"12px"}}>{passwordError}</p>}
              <div className="input-container">
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  required
                />
              </div>
              {confirmPasswordError && <p className="error" style={{color:"red", fontSize:"12px"}}>{confirmPasswordError}</p>}
              <button type="submit">Sign Up</button>
            </form>
            <p className="toggle" onClick={toggleCard}>
              Already have an account? Sign In
            </p>
          </div>
        )}
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Login;