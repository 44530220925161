import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../services/AuthService';

export const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  // Extract email from URL when component mounts
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const emailFromUrl = searchParams.get('email');
    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
  }, []);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (newPassword !== confirmPassword) {
        toast.error('Passwords do not match');
        return;
      }

      // Send reset password request along with email and new password
      await AuthService.resetPassword({ email, newPassword });
      // Display success message
      toast.success('Password updated successfully');
      setTimeout(() => {
        navigate('/');
    }, 3000);
    } catch (error) {
      console.error('Reset password error:', error);
      // Handle error response
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <div className='resetPass'>
    <h2>Password Reset</h2>
    <form
        onSubmit={handleResetPassword}
    >
        <div>
            <label htmlFor="newPassword">New Password:</label>
            <input
                type="password"
                id="newPassword"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
            />
        </div>
        <div>
            <label htmlFor="reEnterPassword">Re-enter Password:</label>
            <input
                type="password"
                id="reEnterPassword"
                placeholder="Re-enter your new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
            />
        </div>
        <button type="submit">Reset Password</button>
    </form>
    <ToastContainer autoClose={2000} />
</div>
  );
};
