import React, { useState } from 'react';
import AuthService from '../services/AuthService';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            const response = await AuthService.forgotPassword(email);
            if (response.error) {
                toast.error(response.error);
                setTimeout(() => {
                    navigate('/forgot-password');
                }, 3000);
            } else {
                toast.success('Password reset email sent successfully. Please check your email.');
                setTimeout(() => {
                    navigate('/');
                }, 3000);
            }
            setLoading(false);
        } catch (error) {
            toast.error('An error occurred while processing your request');
            setLoading(false);
        }
    };
    
    return (
        <div className='forgot'>
            <h2>Forgot Password</h2>
            <ToastContainer />
            <form onSubmit={handleForgotPassword}>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Sending...' : 'Send Reset Email'}
                </button>
            </form>
            <ToastContainer autoClose={2000} />
        </div>
    );
};
