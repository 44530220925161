import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-xs-12"> {/* Adjusted column classes */}
                <div className="intro-text"> {/* Wrapping content in a div */}
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                  <Link
                    smooth
                    to="/#features"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Learn More
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
